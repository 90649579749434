import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/home/tlk/tlk-pay-yookasa/tlk-pay-yookasa/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Navbar","NavbarContent","NavbarBrand"] */ "/home/tlk/tlk-pay-yookasa/tlk-pay-yookasa/node_modules/@nextui-org/navbar/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/home/tlk/tlk-pay-yookasa/tlk-pay-yookasa/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/tlk/tlk-pay-yookasa/tlk-pay-yookasa/src/app/providers/store-provider.tsx");
;
import(/* webpackMode: "eager" */ "/home/tlk/tlk-pay-yookasa/tlk-pay-yookasa/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/home/tlk/tlk-pay-yookasa/tlk-pay-yookasa/node_modules/next/font/google/target.css?{\"path\":\"config/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager" */ "/home/tlk/tlk-pay-yookasa/tlk-pay-yookasa/node_modules/next/font/google/target.css?{\"path\":\"config/fonts.ts\",\"import\":\"Fira_Code\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-mono\"}],\"variableName\":\"fontMono\"}");
