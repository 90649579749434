import { configureStore } from "@reduxjs/toolkit";
import { paymentNamespace } from "@entities/Payment/model/payment.constants";
import { paymentSlice } from "@entities/Payment/model/payment.slice";

export const makeStore = () => {
  return configureStore({
    reducer: {
      [paymentNamespace]: paymentSlice.reducer,
    },
  });
};

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];

export type ThunkApiConfig<RejectValue = any, ExtraArgument = any> = {
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: RejectValue;
  extra: ExtraArgument;
};
